import { SmSpace } from 'm2m.components';
import { useEffect, useState } from 'react';
import { ILoteBase, ILoteInicial, PropsDetails } from '../../common/genericTypes';
import { PaperDetalhe, TextoDetalhe, TituloDetalhe } from '../styled/genericStyled';


const CaracteristicasValorMobiliario: React.FC<PropsDetails> = (props) => {
  const { ofertaPublica } = props;
  const [loteInicial, setLoteInicial] = useState<ILoteInicial>();
  
  useEffect(() => {
    try {
      const grupo = ofertaPublica?.requerimento?.grupos?.find(g => g.series !== null && g.series !== undefined);
      const serie = grupo?.series?.find(s => s.loteInicial !== null && s.loteInicial !== undefined);
      setLoteInicial(serie?.loteInicial)
    } catch {}
  }, [ofertaPublica, ofertaPublica?.requerimento?.grupos])

  return (
    <PaperDetalhe elevation={2}>
      <TituloDetalhe>Informações Gerais</TituloDetalhe>

      <SmSpace />

      <TextoDetalhe>{ofertaPublica?.registro?.numeroRegistro}</TextoDetalhe>
      <TextoDetalhe> Valor Mobiliário: {loteInicial?.valorMobiliario}</TextoDetalhe>
      <TextoDetalhe>Lote Base: {loteInicial?.loteBase?.quantidadeAtivos}</TextoDetalhe>
      <TextoDetalhe>Preço da Emissão: </TextoDetalhe>
      <TextoDetalhe>Taxa de distribuição: </TextoDetalhe>
      <TextoDetalhe>Valor Total do Lote Base: R$ {loteInicial?.loteBase?.valorTotal}</TextoDetalhe>
      <TextoDetalhe>Valor Lote Suplementar: R$ </TextoDetalhe>
      <TextoDetalhe>Valor Lote Adicional: R$ </TextoDetalhe>
      <TextoDetalhe>Descrição do valor mobiliário: </TextoDetalhe>
      <TextoDetalhe>Valor do Lote Base: R$ {loteInicial?.loteBase?.valorTotal}</TextoDetalhe>
      <TextoDetalhe>Total Lote Base + Lote Suplementar + Lote Adicional: R$ </TextoDetalhe>
      
    </PaperDetalhe>
  )
}

export default CaracteristicasValorMobiliario;