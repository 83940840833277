import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LicenseInfo } from '@mui/x-license-pro';
import HotjarMgr from './utils/hotjar';

LicenseInfo.setLicenseKey(
  'de6f2deb89c66ee5e398cd4bff74136aTz02MjY3MCxFPTE3MTEyMjY0NzUyMjAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

(window as any).hotJarMgr = new HotjarMgr();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();