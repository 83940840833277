import { Grid, IconButton, InputBase, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { MdSpace } from 'm2m.components';
import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import laqusSearchLogo from '../../assets/images/laqus-search-logo.svg';
import { tiposValoresMobiliarios } from '../../common/genericTypes';
import { ButtonCircle, GridImg } from '../../components/styled/genericStyled';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../../components/footer/Footer';

interface HomeProps {}

const Home: FunctionComponent<HomeProps> = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const navigate = useNavigate();

  const handleButton = (nome: string) => {
    navigate(`/ofertas-publicas?filterType=${nome}`);
  }

  const redirect = () => {
    navigate(`/ofertas-publicas?searchQuery=${searchQuery}`);
  }

  return (
    <GridAcesso container columns={{ xs: 10 }}>
      <GridImg>
      <Link to="https://www.laqus.com.br/">
        <img src={laqusSearchLogo}/>
      </Link>
        
      </GridImg>

      <Grid>
        <Paper
          component='form'
          onSubmit={redirect}
          sx={{
            p: '8px 16px',
            display: 'flex',
            alignItems: 'center',
            width: '60vw',
            maxWidth: '700px',
            borderRadius: '35px',
          }}
        >
          <InputBase
            sx={{ ml: 1, 
              flex: 1              
             }}
            id='search-input'
            placeholder='Pesquise aqui ou selecione um valor mobiliário'
            value={searchQuery}            
            onInput={(event) => setSearchQuery((event.target as HTMLInputElement).value)}
          />
          <IconButton type='submit' aria-label='search'>
            <SearchIcon />
          </IconButton>
        </Paper>
      </Grid>

      <MdSpace />

      <GridCards container columns={{xs: 4, sm: 4, md: 4}}>
        {tiposValoresMobiliarios.map((tipo, idx) => (
          <GridCardItem item xs={2} sm={1} md={1} key={idx}>
            <label htmlFor='button' style={{ padding: '3vw', cursor: 'pointer' }}>
              <ButtonCircle type='button' onClick={() => handleButton(tipo.nome)}>
                <span className='btnAbreviacao'>{tipo.abreviacao}</span></ButtonCircle>
              <DivNome>{tipo.nome}</DivNome>
            </label>
          </GridCardItem>
        ))}
      </GridCards>
      <Footer/>
    </GridAcesso>
  );
};

const GridAcesso = styled(Grid)`
  padding-top: 15vh;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;

  @media (max-width: 1023px) {
    padding-top: 3vh;
  }
  @media(max-height: 600px) {
    padding-top: 3vh;
  }
`;

const GridCards = styled(Grid)`
  text-align: center;
  display: flex;
  justify-content: center;
  max-width: 800px !important;
`;

const GridCardItem = styled(Grid)`
  text-align: center;
  display: flex;
  justify-content: center;
`;

const DivNome = styled.div`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.4px;
  width: 100px;
  padding-top: 10px;
`;

export default Home;
