import { Grid } from '@mui/material';
import styled from 'styled-components';
import { PropsDetails } from '../../common/genericTypes';
import CaracteristicasValorMobiliario from './CaracteristicasValorMobiliario';
import Participante from './Participante';
import InformacoesGerais from './InformacoesGerais';
import InformacoesOferta from './InformacoesOferta';

const Details: React.FC<PropsDetails> = (props) => {
  return (
    <Grid container columns={{ xs: 12, lg: 12 }} spacing={3}>
      <Grid item xs={12} md={12} sm={12}>
        <H5>Oferta Pública</H5>
      </Grid>

      <GridDetails item xs={12} lg={5}>
        <Grid container>
          <GridDetails item xs={12} lg={12}>
          <InformacoesGerais ofertaPublica={props.ofertaPublica}/>
          </GridDetails>

          <GridDetails item xs={12} lg={12}>
            <InformacoesOferta ofertaPublica={props.ofertaPublica}/>
          </GridDetails>
        </Grid>
      </GridDetails>

      <GridDetails item xs={12} lg={4}>
        <Grid container>
          <GridDetails item xs={12}>
            <Participante ofertaPublica={props.ofertaPublica} titulo={'Emissor'} tipo={'EMISSOR'}/>
          </GridDetails>
          <GridDetails item xs={12}>
            <Participante ofertaPublica={props.ofertaPublica} titulo={'Ofertante'} tipo={'OFERTANTE'}/>
          </GridDetails>

          <GridDetails item xs={12}>
            <Participante ofertaPublica={props.ofertaPublica} titulo={'Coordenador Líder'} tipo={'COORDENADOR'}/>
          </GridDetails>

          <GridDetails item xs={12}>
            <CaracteristicasValorMobiliario ofertaPublica={props.ofertaPublica}/>
          </GridDetails>
        </Grid>
      </GridDetails>

      {/* <GridDetails item xs={8}>
        <Documentos ofertaPublica={props.ofertaPublica}/>
      </GridDetails> */}

    </Grid>
  )
}

const H5 = styled.h5`
  color: #008770;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
`;

const GridDetails= styled(Grid)`
  padding-bottom: 15px;
`;

export default Details;
