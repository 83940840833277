import { createTheme } from '@mui/material';
import { ptBR } from '@mui/material/locale';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#00E08E',
    },
    secondary: {
      main: '#fff',
    },
    error: {
      main: '#FF5A5F',
    },
    warning: {
      main: '#FFCF40',
    },
  },
  typography: {
    fontFamily: ['Rubik'].join(','),
    h1: {
      fontSize: '60px',
      fontWeight: 600,
    },
    h2: {
      fontSize: '56px',
      fontWeight: 600,
    },
    h3: {
      fontSize: '24px',
      fontWeight: 400,
    },
    body1: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    body2: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    subtitle1: {
      fontSize: '12px',
      lineHeight: '18px',
    },
    subtitle2: {
      fontSize: '12px',
      lineHeight: '18px',
    },
    button: {
      fontFamily: 'Rubik Bold',
      fontSize: '12px',
      lineHeight: '15px',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '24px',
          fontSize: '12px',
          padding: '16px 30px',
        },
        sizeSmall: {
          padding: '10px 20px',
        },
        outlined: {
          padding: '16px 30px',
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: '#00E08E',
          },
        },
        containedSecondary: {
          border: `1px solid #00E08E`,
          '&:hover': {
            backgroundColor: 'inherit',
          },
        },
        outlinedSecondary: {
          border: `1px solid black`,
          color: 'black',
          '&:hover': {
            border: `1px solid black`,
          },
        },
      },
    },
  }
}, ptBR);
