import { Box, Grid } from '@mui/material';
import { connectHits } from 'react-instantsearch-dom';
import { DataGridPro, ptBR } from '@mui/x-data-grid-pro';
import { useCallback, useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { localizedTextsMap } from '../utils/dataGridUtils';
import { useEffect } from 'react';
import { IOfertaPublica } from '../common/genericTypes';
import { animateScroll as scroll, Element, scroller } from 'react-scroll';
import Details from './details/Details';
import api from '../services/api';

interface Props {
  hits: any;
}

const PublicOfferingHits: React.FC<Props> = (prop) => {
  const hits = prop.hits;
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(50);
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [ofertaPublica, setOfertaPublica] = useState<IOfertaPublica>();
  const [firstScroll, setFirstScroll] = useState<boolean>(true);

  const returnDiv = (html: string) => {
    return <div dangerouslySetInnerHTML={{ __html: html }} />
  }

  const returnRowCellDiv = (html: string) => {
    return <div
    style={{ 
      'fontSize': '13px',
      'overflowWrap':'break-word',
      'wordBreak':'break-all'
     }} dangerouslySetInnerHTML={{ __html: html }} />
  }


  const returnDivId = (html: string) => {
    return <div style={{
      'color': '#00A388',
      'fontSize': '14px',
      'cursor': 'pointer'
    }} dangerouslySetInnerHTML={{ __html: html }} />
  }

  useEffect(() => { console.log('prop', prop) }, [])

  const getGridColumns: () => GridColDef<never, any, any>[] = useCallback(
    () => [
      {
        field: 'id',
        headerName: 'CVM',
        type: 'string',
        width: 65,
        filterable: true,
        sortable: true,
        renderCell: (params: any) => {
          let row = (params.row as any)
          return returnDivId(row._highlightResult.id?.value ?? row.id);
        },
      },
      {
        field: 'nomeEmissor',
        headerName: 'Emissor',
        type: 'string',
        width: 300,
        minWidth:100,
        filterable: true,
        sortable: true,
        resizable: true,

        renderCell: (params: any) => {
          let row = (params.row as any)
          return returnRowCellDiv(row._highlightResult.nomeEmissor?.value ?? row.nomeEmissor);
        },
      },
      {
        field: 'valorMobiliario',
        headerName: 'Valor Mobiliário',
        type: 'string',
        width: 150,
        filterable: true,
        sortable: true,
        renderCell: (params: any) => {
          let row = (params.row as any)
          return returnRowCellDiv(row._highlightResult.valorMobiliario?.value ?? row.valorMobiliario);
        },
      },
      {
        field: 'tipoDeOferta',
        headerName: 'Oferta',
        type: 'string',
        width: 130,
        filterable: true,
        sortable: true,
        renderCell: (params: any) => {
          let row = (params.row as any)
          return returnRowCellDiv(row._highlightResult.tipoDeOferta?.value ?? row.tipoDeOferta);
        },
      },
      {
        field: 'dataRegistroConcedido',
        headerName: 'Registro',
        type: 'date',
        width: 130,
        filterable: true,
        sortable: true,
        renderCell: (params: any) => {
          let row = (params.row as any)
          return returnRowCellDiv(row._highlightResult.dataRegistroConcedido?.value ?? row.dataRegistroConcedido);
        },
      },
      {
        field: 'qualificado',
        headerName: 'Qualificado',
        type: 'string',
        width: 100,
        filterable: true,
        sortable: true,
        renderCell: (params: any) => {
          let row = (params.row as any)
          if (row.qualificado !== 'Em Breve')
            return returnRowCellDiv(row._highlightResult.qualificado?.value ?? row.qualificado);
          else
            return returnRowCellDiv(row._highlightResult.dataQualificado?.value ?? row.dataQualificado);
        },
      },
      {
        field: 'varejo',
        headerName: 'Varejo',
        type: 'string',
        width: 100,
        filterable: true,
        sortable: true,
        renderCell: (params: any) => {
          let row = (params.row as any)
          if (row.varejo !== 'Em Breve')
            return returnRowCellDiv(row._highlightResult.varejo?.value ?? row.varejo);
          else
            return returnRowCellDiv(row._highlightResult.dataVarejo?.value ?? row.dataVarejo);
        },
      },
      {
        field: 'coordenador',
        headerName: 'Coordenador Líder',
        type: 'string',
        width: 300,
        filterable: true,
        sortable: true,
        renderCell: (params: any) => {
          let row = (params.row as any)
          return returnRowCellDiv(row._highlightResult.coordenador?.value ?? row.coordenador);
        },
      },
    ],
    [hits]
  );

  const scrollDetails = () => {
    if (firstScroll) {
      scroll.scrollToBottom();
      setFirstScroll(false)
    } else {
      scroller.scrollTo('myScrollToElement', {
        smooth: true,
      });
    }
  }

  const handleRowClick = (params: any) => {
    if (params.id == ofertaPublica?.id)
      setOpenDetails(false);

    api
      .get(`ofertas-publicas-cvm/${params.id}`)
      .then((resp) => {
        setOfertaPublica(resp.data)
        setOpenDetails(true);
        scrollDetails();
      })
  }

  return (
    <Grid container>
      <Grid item xs={12} md={12} sm={12}>
        <Box
          sx={{
            height: '60vh',
            paddingTop: '5px',
            width: '100%',
          }}
        >
          <DataGridPro
            rows={hits}
            columns={getGridColumns()}
            rowsPerPageOptions={[5, 10, 25, 50, 100, 250]}
            page={page}
            onPageChange={(newPage) => setPage(newPage)}
            rowCount={hits?.length}
            pagination
            onPageSizeChange={(newPageSize) => {
              setPageSize(newPageSize); 
              setPage(0);
            }}
            pageSize={pageSize}
            loading={false}
            /*localeText={localizedTextsMap}*/
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={handleRowClick}
            style={{
              position: 'relative',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
            sx={{
              '& .css-yrdy0g-MuiDataGrid-columnHeaderRow, .MuiDataGrid-columnHeaders': {
                backgroundColor: '#005B6A',
                color: '#FFFFFF',
                fontWeight: 500,
                fontSize: '16px',
                display: 'flex !important',
                alignItems: 'center !important',
              },
              '& .MuiDataGrid-columnHeaderTitleContainer': {
                display: 'flex !important',
                alignItems: 'center !important',
              }
            }}
          />
        </Box>
      </Grid>

      <Grid item xs={12} md={12} sm={12} id="details">
        <div id='scroll'></div>
        <Element name="myScrollToElement"></Element>
        {openDetails && <Details ofertaPublica={ofertaPublica!} />}
      </Grid>
    </Grid>
  );
};

export default connectHits(PublicOfferingHits);
