import { SmSpace } from 'm2m.components';
import { IOfertaPublica, PropsDetails } from '../../common/genericTypes';
import { PaperDetalhe, TextoDetalhe, TituloDetalhe } from '../styled/genericStyled';

const InformacoesGerais: React.FC<PropsDetails> = (props) => {
  const { ofertaPublica } = props;
  return (
    <PaperDetalhe elevation={2}>
      <TituloDetalhe>Informações Gerais</TituloDetalhe>

      <SmSpace />

      <TextoDetalhe>Status: {ofertaPublica?.informacoesComplementares?.ultimoStatus?.status}</TextoDetalhe>
      <TextoDetalhe>Processo - SEI: {ofertaPublica?.registro?.numeroProcesso}</TextoDetalhe>
      <TextoDetalhe>Nº do Registro: {ofertaPublica?.registro?.numeroRegistro}</TextoDetalhe>
      <TextoDetalhe>Tipo Requerimento: {ofertaPublica?.registro?.nomeTipoRequerimento}</TextoDetalhe>
      <TextoDetalhe>Valor Mobiliário: {ofertaPublica?.registro?.nomeValorMobiliario}</TextoDetalhe>
      <TextoDetalhe>Valor Total: R$ {ofertaPublica?.registro?.valorTotalEmReais}</TextoDetalhe>
      <TextoDetalhe>Data: {ofertaPublica?.registro?.data}</TextoDetalhe>
      
    </PaperDetalhe>
  )
}



export default InformacoesGerais;