import { Grid, Paper } from '@mui/material';
import styled from 'styled-components';

export const ButtonCircle = styled.button`
  background: #e9f3f2;
  border-radius: 50%;
  border-style: none;
  height: 70px;
  width: 70px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 17px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  cursor: pointer;
`;

export const GridImg = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 5vh;
`;

export const TituloDetalhe = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #00696B;
`;

export const TextoDetalhe = styled.span`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #3F4948;
  padding-bottom: 5px;
`;

export const PaperDetalhe = styled(Paper)`
  padding: 15px;
  min-width: 30vw;
`;