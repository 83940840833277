import React, { useEffect, useState } from 'react';
import {
  InstantSearch
} from 'react-instantsearch-dom';
import TypesenseInstantsearchAdapter from 'typesense-instantsearch-adapter';
import PublicOfferingHits from '../../components/Hit';
import '../../App.css';
import { Grid } from '@mui/material';
import laqusSearchLogo from '../../assets/images/laqus-search-logo.svg';
import styled from 'styled-components';
import { GridImg } from '../../components/styled/genericStyled';
import { MdSpace } from 'm2m.components';
import CustomSearchBox from '../../components/SearchBox';
import CardRefinementList from '../../components/RefinementList';
import { Link } from 'react-router-dom';
import RefinementSelectQualificado from '../../components/SelectQualificado';
import RefinementSelectVarejo from '../../components/SelectVarejo';
import Footer from '../../components/footer/Footer';

interface SearchProps { }

const SearchResults: React.FC<SearchProps> = (props) => {
  const [queryBy, setQueryBy] = useState<string>(
    'nomeEmissor,valorMobiliario,tipoDeOferta,status,dataStatus,dataRegistroConcedido,qualificado,dataQualificado,varejo,dataVarejo,coordenador'
  );
  const [facetBy, setFacetBy] = useState<string>('qualificado,varejo');

  const [
    typesenseInstantSearchAdapter,
    setTypesenseInstantSearchAdapter,
  ] = useState<TypesenseInstantsearchAdapter>(
    new TypesenseInstantsearchAdapter({
      server: {
        apiKey: 'xyz',
        nodes: [
          {
            host: process.env['REACT_APP_TYPESENSE_HOST']?.toString() ?? '',
            port: Number(process.env['REACT_APP_TYPESENSE_PORT']),
            protocol:
              process.env['REACT_APP_TYPESENSE_PROTOCOL']?.toString() ?? '',
          },
        ],
      },
      additionalSearchParameters: {
        query_by: queryBy,
        facet_by: facetBy,
        limit_hits: 1000,
        per_page: 250,
      },
    })
  );

  useEffect(() => {
    setTypesenseInstantSearchAdapter(
      new TypesenseInstantsearchAdapter({
        server: {
          apiKey: 'xyz',
          nodes: [
            {
              host: process.env['REACT_APP_TYPESENSE_HOST']?.toString() ?? '',
              port: Number(process.env['REACT_APP_TYPESENSE_PORT']),
              protocol:
                process.env['REACT_APP_TYPESENSE_PROTOCOL']?.toString() ?? '',
            },
          ],
        },
        additionalSearchParameters: {
          query_by: queryBy,
          facet_by: facetBy,
          limit_hits: 1000,
          per_page: 250,
        },
      })
    );
  }, [queryBy, facetBy]);

  return (
    <>
      <InstantSearch
        indexName='publicOffering-new'
        searchClient={typesenseInstantSearchAdapter.searchClient}
      >
        <GridMain container>

          <GridImgSearch item lg={1.7} xs={12}>
            <Link to={'/'}>
              <img src={laqusSearchLogo} style={{ width: '134px' }} alt='Laqus Search Logo' />
            </Link>
          </GridImgSearch>

          <MdSpace />
          
          <GridCenter item lg={10} xs={12}>
            <CustomSearchBox />
          </GridCenter>

          <Grid item lg={6} xs={12}>
            <GridCenter item lg={6} xs={12}>
              <H5>Valores Mobiliários</H5>
            </GridCenter>

            <GridRefinamentoItem item lg={11} xs={12}>
              <CardRefinementList attribute='valorMobiliario' />
            </GridRefinamentoItem>
          </Grid>

          <GridCenter lg={1} xs={0} />

          <Grid item lg={5} xs={12}>
            <GridCenter className='publico-alvo' item lg={12} xs={12}>
              <H5>Público Alvo</H5>
            </GridCenter>

            <GridRefinamentoSelect item lg={6} xs={12}>
              <RefinementSelectQualificado attribute='qualificado' />
              <RefinementSelectVarejo attribute='varejo' />
            </GridRefinamentoSelect>
          </Grid>
          
          <GridCenter item xs={12}>
            <PublicOfferingHits />
          </GridCenter>
        </GridMain>
      </InstantSearch>

      <Footer/>
    </>
  );
};

const GridMain = styled(Grid)`
  padding: 5vh 5vw 1vw 7vw;
  @media (max-width: 1200px) {
    padding: 2vh 3vw;
  }
`;

const GridImgSearch = styled(GridImg)`
  @media (min-width: 1200px) {
    justify-content: flex-start;
  }
`;

const GridCenter = styled(Grid)`
  padding-top: 2vh;
  @media (max-width: 1200px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

const GridRefinamentoItem = styled(GridCenter)`
`;

const GridRefinamentoSelect = styled(GridCenter)`
  display: flex;
  @media (max-width: 1200px) {
    max-width: 100% !important;
    align-items: center !important;
  }
  @media(max-width: 600px) {
    flex-wrap: wrap;
  }
`;

const H5 = styled.h5`
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #008770;
  margin: 0;
  padding-top: 5vh;

  @media (max-width: 1200px) {
    padding: 5px;
  }
`;


export default SearchResults;
