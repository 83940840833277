export interface TipoValoreMobiliario {
  nome: string;
  abreviacao: string;
}

export const tiposValoresMobiliarios: TipoValoreMobiliario[] = [
  {
    nome: 'Notas Comerciais',
    abreviacao: 'NC',
  },
  {
    nome: 'Cotas de FIF',
    abreviacao: 'FIF',
  },
  {
    nome: 'Debêntures',
    abreviacao: 'DEB',
  },
  {
    nome: 'Cotas de FIDC',
    abreviacao: 'FIDC',
  },
  {
    nome: 'Cotas de FII',
    abreviacao: 'FII',
  },
  {
    nome: 'Certificados de Recebíveis Imobiliários',
    abreviacao: 'CRI',
  },
  {
    nome: 'Certificados de Recebíveis do Agronegócio',
    abreviacao: 'CRA',
  },
];

interface IRegistro {
  idRequerimento: string;
  totalRegistros: number;
  numeroProtocolo: string;
  numeroProcesso: string;
  numeroRegistro: string;
  nomeValorMobiliario: string;
  tipoDeOferta: string;
  nomeEmissor: string;
  nomeCoordenadorLider: string;
  valorTotalEmReais: string;
  data: string;
  nomeTipoRequerimento: string;
  vasoComunicante: boolean;
  possuiBook: boolean;
}

interface IInformacoesGerais {
  ofertaMercado: boolean;
  numeroProcesso: string;
  numeroRegistro: string;
  nomeValorMobiliario: string;
  valorTotalInicial: string;
  valorTotalFinal: string;
  valorTotalLoteBaseInicial: string;
  valorTotalLoteBaseFinal: string;
  data: string;
  nomeTipoRequerimento: string;
  status: string;
  automaticaSemBook: boolean,
  ofertaAutomatica: boolean,
}

export interface ILoteBase {
  quantidadeAtivos: string;
  valorNominal: string;
  custoUnitario: string;
  valorTotal: string;
}

export interface ILoteInicial {
  valorMobiliario: string;
  loteBase: ILoteBase;
}

interface ISerie {
  loteInicial: ILoteInicial
}

interface IGrupo {
  series: ISerie[]
}

interface IRequerimento {
  informacoesGerais: IInformacoesGerais;
  tipoOfertaRequerimento: string;
  vasosComunicantes: boolean;
  bookPreenchido: boolean;
  grupos: IGrupo[]
}

interface IParticipante {
  razaoSocial: string;
  cnpjInstituicao: string;
  tipo: string;
}

interface IStatus {
  status: string;
  data: string;
  hora: string;
  observacao: any;
}

interface IDocumentoPublicado {
  idDocumento: string;
  nome: string;
  data: string;
  hora: string;
  extencao: string;
  tamanho: number;
  valor: string;
}

interface ICampoCadastrado {
  campoNome: string;
  campoTipo: string;
  campoValor: any;
  campoMascara: string;
  visivel: boolean;
}

interface IAcaoObjeto {
  codigo: string | any;
  tipoOferta: string | any;
  tipoOfertaRequerimento: string | any;
  descricao: string | any;
  especie: string | any;
  classe: string | any;
  quantidadeAtivos: string | any;
  valorAtivos: string | any;
  custoUnitario: string | any;
  quantidadeAtivosLoteSuplementar: string | any;
  quantidadeAtivosLoteAdicional: string | any;
  valorTotal: string | any;
  bookValorTotal: string | any;
  vasosComunicantes: string | any;
  bookTaxa: string | any;
  bookTaxaSuplementar: string | any;
  bookTaxaAdicional: string | any;
  bookQuantidadeAtivos: string | any;
  bookQuantidadeAtivosSuplementar: string | any;
  bookQuantidadeAtivosAdicional: string | any;
  bookValorNominal: string | any;
  bookValorSuplementar: string | any;
  bookValorAdicional: string | any;
  bookCustoUnitario: string | any;
  bookCustoUnitarioAdicional: string | any;
  bookCustoUnitarioSuplementar: string | any;
  bookFlagPreenchido: string | any;
  valorBkRegularRealizadoPrevisto: string | any;
  valorBkRegularRealizadoEfetivo: string | any;
  valorBkRegularTaxaPrevisto: string | any;
  valorBkRegularTaxaEfetivo: string | any;
  valorBkRegularAtivosPrevisto: string | any;
  valorBkRegularAtivosEfetivo: string | any;
  valorBkRegularNominalPrevisto: string | any;
  valorBkRegularNominalEfetivo: string | any;
  valorBkRegularTipoValor: string | any;
  valorBkSuplementarRealizadoPrevisto: string | any;
  valorBkSuplementarRealizadoEfetivo: string | any;
  valorBkSuplementarTaxaPrevisto: string | any;
  valorBkSuplementarTaxaEfetivo: string | any;
  valorBkSuplementarAtivosPrevisto: string | any;
  valorBkSuplementarAtivosEfetivo: string | any;
  valorBkSuplementarNominalPrevisto: string | any;
  valorBkSuplementarNominalEfetivo: string | any;
  valorBkSuplementarTipoValor: string | any;
  valorBkAdicionalRealizadoPrevisto: string | any;
  valorBkAdicionalRealizadoEfetivo: string | any;
  valorBkAdicionalTaxaPrevisto: string | any;
  valorBkAdicionalTaxaEfetivo: string | any;
  valorBkAdicionalAtivosPrevisto: string | any;
  valorBkAdicionalAtivosEfetivo: string | any;
  valorBkAdicionalNominalPrevisto: string | any;
  valorBkAdicionalNominalEfetivo: string | any;
  valorBkAdicionalTipoValor: string | any;
  grupoCodigo: string | any;
  grupoCodigoSerie: string | any;
  grupoTipo: string | any;
  grupoQuantidadeAtivos: string | any;
  grupoValorNominal: string | any;
  camposCadastrados: ICampoCadastrado[];
  registroValorMobiliario: boolean;
  numeroRegistro: string;
}

interface IInfoOferta {
  codigo: number;
  campoNome: string;
  valor: string
}

interface IInformacoesComplementares {
  tipoValorMobiliario: string;
  tipoRequerimento: number;
  tipoLockup: number;
  qualificado: string;
  dataQualificado: string;
  varejo: string;
  dataVarejo: string;
  observacoes: string;
  ultimoStatus: IStatus
}

export interface IOfertaPublica {
  id: string;
  dataCriacao: string;
  registro: IRegistro;
  requerimento: IRequerimento;
  participantes: IParticipante[];
  relacionamento: any[];
  historicoStatus: IStatus[];
  documentosPublicados: IDocumentoPublicado[];
  acaoObjeto: IAcaoObjeto[];
  infOferta: IInfoOferta[];
  informacoesComplementares: IInformacoesComplementares;
}

export interface PropsDetails {
  ofertaPublica: IOfertaPublica
}
