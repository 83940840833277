import { Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';
import { tiposValoresMobiliarios } from '../common/genericTypes';
import { useSearchParams } from 'react-router-dom';

const RefinementList = ({ items, currentRefinement, refine }: any) => {
  const [searchParams] = useSearchParams();
  const [selected, setSelected] = useState<number | null>(null)

  useEffect(() => {
    const filterType = searchParams.get('filterType');
    if (!filterType)
      return;
    
    setSelected(tiposValoresMobiliarios.findIndex(t => t.nome == filterType));
    refine(filterType);
  }, [])

  const handleRefinement = (name: string, index: number) => {
    if (index === selected) {
      setSelected(null); 
      refine(tiposValoresMobiliarios.map(item => item.nome));
    } else {
      setSelected(index);
      refine(name);
    }
  }

  return (
    <GridRefinement>
      {tiposValoresMobiliarios.map((item, idx) => (
        <CustomButton key={idx} className={selected == idx ? 'active' : ''}
          variant='contained'          
          onClick={event => {
            event.preventDefault();
            handleRefinement(item.nome, idx);
          }}
        >
          {item.abreviacao}
        </CustomButton>
      ))}
    </GridRefinement>
  )
};

const GridRefinement = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '80%',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  }
});

const CustomButton = styled(Button)({
  backgroundColor: '#e9f3f2',
  borderRadius: '8px',
  color: '#00696B',
  fontWeight: 'bold',
  padding: '20px',
  width: '49px',
  height: '32px',
  marginTop: '10px',
  fontSize: '14px',
  fontFamily: 'Rubik',
  boxShadow: 'none',
  '&.active': {
    boxShadow: 'none',
    backgroundColor: '#00A388',
    color: '#FFFFFF'
  },
});

const CardRefinementList = connectRefinementList(RefinementList);
export default CardRefinementList;
