import { hotjar } from 'react-hotjar';

class HotjarMgr {

    public constructor(){        
        this.init();
    }

    private init(): void {
        try{
            var id: number = -1;
            
            if(!process.env['REACT_APP_HOTJAR_ID']){
                throw new Error("ID do hotjar nao definido.");
            }

            id = Number.parseInt(process.env['REACT_APP_HOTJAR_ID']);
            if(!id){
                console.log("Hotjar ID invalido");
            }

            hotjar.initialize(id, 6);
            hotjar.identify('USER_ID', { userProperty: 'value' });
        }catch(e){
            console.error("Tivemos um problema ao iniciar o hotjar...", e);
        }
    }

    public registerStateChange(changeDesc: string): void {
        if (!hotjar.initialized()) {
            this.init();
        }

        hotjar.stateChange(changeDesc);
    }

    public registerEvent(evtDesc: string): void {
        if (!hotjar.initialized()) {
            this.init();
        }

        hotjar.event(evtDesc);
    }
}

export default HotjarMgr;