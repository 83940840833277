import { SmSpace } from 'm2m.components';
import { IOfertaPublica, PropsDetails } from '../../common/genericTypes';
import { PaperDetalhe, TextoDetalhe, TituloDetalhe } from '../styled/genericStyled';

interface Props {
  ofertaPublica: IOfertaPublica;
  titulo: string;
  tipo: string;
}

const Participante: React.FC<Props> = (props) => {
  const { ofertaPublica, titulo, tipo } = props;

  return (
    <PaperDetalhe elevation={2}>
      <TituloDetalhe>{titulo}</TituloDetalhe>

      <SmSpace />

      <TextoDetalhe>Nome: {ofertaPublica?.participantes?.find(p => p.tipo == tipo)?.razaoSocial}</TextoDetalhe>
      <TextoDetalhe>CNPJ/CPF: {ofertaPublica?.participantes?.find(p => p.tipo == tipo)?.cnpjInstituicao}</TextoDetalhe>
    </PaperDetalhe>
  )
}

export default Participante;