import SearchResults from './pages/results/SearchResults';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from './pages/home/Home';
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme';
import ReactGA from 'react-ga';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "/ofertas-publicas",
    element: <SearchResults />,
  }
]);

const TRACKING_ID = process.env['REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID'] ?? '';

ReactGA.initialize(TRACKING_ID, {
  //debug: true,
  titleCase: true,
  alwaysSendToDefaultTracker: true,
  useExistingGa: false,
  gaOptions: {
    siteSpeedSampleRate:100,
    sampleRate:100
  }  
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GoogleReCaptchaProvider reCaptchaKey={process.env['REACT_APP_RE_CAPTCHA_V3_SITE_KEY'] as string}>
        <RouterProvider router={router} />
      </GoogleReCaptchaProvider>
    </ThemeProvider>
  );
}

export default App;
