import { FormControl, Grid, MenuItem, OutlinedInput, Select } from '@mui/material';
import { useState } from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';
import styled from 'styled-components';

// TODO: DRY
const SelectPublicoAlvoVarejo = ({ items, currentRefinement, refine }: any) => {
  const [selected, setSelected] = useState<string | null>(null)

  const options = ['Livre', 'Em Breve', 'N/D', 'Todos']

  const handleRefinement = (name: string | null) => {
    console.log('chegou aqui', name)
    if (name === null || name == selected || name === 'Todos') {
      setSelected(null); 
      refine(options.map(item => item));
    }
    else {
      setSelected(name);
      refine(name);
    }
  }

  return (
    <GridSelect>
      <FormControl sx={{ m: 1, width: 300, mt: 3, marginTop: 0 }}>
        <Select
          displayEmpty
          value={selected}
          onChange={(e) => handleRefinement(e.target.value)}
          renderValue={(selected2) => {
            if (selected2 === null)
              return <em>Varejo</em>;        

            return selected2;
          }}
          input={<OutlinedInput />}
        >
          <MenuItem disabled value="">
            <em>Público-alvo Varejo</em>
          </MenuItem>

          {options
            .filter(option => selected === null ? option !== 'Todos' : true)
            .map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
      
    </GridSelect>
  )
};

const GridSelect = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: right;
  width: 100%;
  @media (max-width: 1200px) {
    width: 80%;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
`;

const RefinementSelectVarejo = connectRefinementList(SelectPublicoAlvoVarejo);
export default RefinementSelectVarejo;
