import { IconButton, InputBase, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { connectSearchBox } from 'react-instantsearch-dom';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ReactGA from 'react-ga';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const SearchBox = ({ currentRefinement, isSearchStalled, refine}: any) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [searchParams] = useSearchParams();
  const [recaptchaToken, setRecaptchaToken] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  
  useEffect(() => {
    const searchQuery = searchParams.get('searchQuery');
    if (!searchQuery)
      return;
    
    setSearchTerm(searchQuery);
    refine(searchQuery);
  }, []);

  const handleChange = (value: string) => {
    handleReCaptchaVerify();
    setSearchTerm(value);
  }

  const handleSubmit = (e: any) => {
    refine(searchTerm);
    e.preventDefault();
    //https://www.npmjs.com/package/react-ga
    //https://developers.google.com/analytics/devguides/collection/analyticsjs/events

    const evt : ReactGA.EventArgs = {
      category: "view_search_results",
      label: "view_search_results",
      action: searchTerm
    };
    
    (window as any).gtag('event', 'view_search_results', {
      search_term: searchTerm,
    });        
    
    var x = ReactGA.event(evt);
    console.log("GA event: ", evt);
  }

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('yourAction');
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return (
  <Paper
    component='form'
    onSubmit={handleSubmit}
    sx={{
      p: '8px 16px',
      display: 'flex',
      alignItems: 'center',
      width: '80vw',
      maxWidth: '613px',
      height: '42px',
      borderRadius: '35px',
    }}
  >
    <InputBase
      sx={{ ml: 1, flex: 1 }}
      placeholder='Pesquise aqui'
      value={searchTerm}
      onChange={event => handleChange(event.currentTarget.value)}
    />
    <IconButton type='submit' aria-label='search'>
      <SearchIcon />
    </IconButton>
  </Paper>
)};

const CustomSearchBox = connectSearchBox(SearchBox);
export default CustomSearchBox;