import Link from "@mui/material/Link";

const Footer = () => {
    const year = new Date().getFullYear();

    return (
        <>
            <footer>
                <Link
                    sx={{
                        color: '#FFFFFF'
                    }}
                    href="https://www.laqus.com.br" target="_blank">
                    {`Copyright © Laqus ${year}`}
                </Link>
            </footer>
        </>
    );
};

export default Footer;