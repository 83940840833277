import { SmSpace } from 'm2m.components';
import { PropsDetails } from '../../common/genericTypes';
import { PaperDetalhe, TextoDetalhe, TituloDetalhe } from '../styled/genericStyled';


const InformacoesOferta: React.FC<PropsDetails> = (props) => {
  const { ofertaPublica } = props;
  return (
    <PaperDetalhe elevation={2}>
      <TituloDetalhe>Informações da Oferta</TituloDetalhe>

      <SmSpace />

      <TextoDetalhe>Oferta inicial?: </TextoDetalhe>
      <TextoDetalhe>Data da deliberação que aprovou a oferta: {ofertaPublica?.infOferta?.find(i => i.campoNome === 'Data da deliberação que aprovou a oferta')?.valor}</TextoDetalhe>
      <TextoDetalhe>Administrador: </TextoDetalhe>
      <TextoDetalhe>Gestor: </TextoDetalhe>
      <TextoDetalhe>Escriturador: {ofertaPublica?.infOferta?.find(i => i.campoNome === 'Escriturador')?.valor}</TextoDetalhe>
      <TextoDetalhe>Custodiante: </TextoDetalhe>
      <TextoDetalhe>Destinação dos recursos: {ofertaPublica?.infOferta?.find(i => i.campoNome === 'Destinação dos recursos')?.valor}</TextoDetalhe>
      <TextoDetalhe>Regime de distribuição: {ofertaPublica?.infOferta?.find(i => i.campoNome === 'Regime de distribuição')?.valor}</TextoDetalhe>
      
    </PaperDetalhe>
  )
}

export default InformacoesOferta;